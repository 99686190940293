import React from 'react';
import { Route, Switch } from 'react-router-dom';

import News from '../containers/news';

// import NotFound from './not-found';

export default () => (
  <Switch>
    <Route exact path="*" component={News} />

    {/* <Route component={NotFound} /> */}
  </Switch>
);
