import * as types from '../actions/action-types';
import defaultState from '../store/default-state';

export default (state = {}, action) => {
  switch (action.type) {
    case types.ERROR: {
      const { error } = action;
      return {
        ...defaultState,
        error,
        currentArticleUrl: action.url,
      };
    }

    case types.REQUEST_TOPIC: {
      return {
        ...state,
        error: null,
        isLoading: true,
        currentTopic: '',
        topics: [],
        currentArticleUrl: '',
        article: null
      };
    }

    case types.RECEIVE_TOPIC: {
      const { data } = action;
      return {
        ...state,
        isLoading: false,
        currentTopic: action.topic,
        topics: data
      };
    }

    case types.REQUEST_ARTICLE: {
      const { length } = action;
      if (length === 'full') {
        return {
          ...state,
          isFullArticleLoading: true
        }
      } else {
        return {
          ...state,
          error: null,
          isLoading: true,
          topics: [],
          currentArticleUrl: '',
          article: null,
          isFullArticle: false,
          isFullArticleLoading: false
        };
      }
    }

    case types.RECEIVE_ARTICLE: {
      const { url, data, length } = action;
      return {
        ...state,
        isLoading: false,
        currentArticleUrl: url,
        article: {
          ...data
        },
        isFullArticle: length === 'full',
        isFullArticleLoading: false
      };
    }

    case types.REMOVE_IMAGE: {
      return {
        ...state,
        article: {
          ...state.article,
          image: null
        }
      };
    }

    default:
      return state;
  }
};
