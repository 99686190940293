import React from 'react';

import Alert from 'react-bootstrap/lib/Alert';

const Error = ({ status, message }) => {
    let errorTitle = 'Cannot connect to server...';
    let errorMessage = 'Either your Internet isn\'t working or our server is down. You could determine if your Internet is working by attempting to visit another website.';
    let bsStyle = "danger";

    if (status && status === 401) {
        errorTitle = 'Unauthorized';
        errorMessage = 'Either the credentials provided are incorrect or your session has expired. Please try again.';
    } else if (status && status === 404) {
        bsStyle = "warning";
        errorTitle = 'Not Found';
        errorMessage = 'Unable to retrieve the article from the source website.';
    } else if (status && status === 500) {
        errorTitle = 'Unexpected Error';
        errorMessage = 'An unexpected error has been received from our server. Details have been logged for analysis by support.';
    } else if (status || message) {
        errorMessage = `HTTP STATUS ${status || ''} ${message || ''}`;
    }

    return (
        <Alert bsStyle={bsStyle}>
            {errorTitle &&
                <strong>{errorTitle}</strong>
            }
            <p>{errorMessage}</p>
        </Alert>
    )
};

export default Error
