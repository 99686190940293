// import fetch from 'isomorphic-fetch';

/**
 * Middleware to handle all API requests. Dispatch an action in the format:
 * ```
 *     {
 *         api: {
 *             endpoint: 'api-endpoint' (i.e 'titles/${id}'),
 *             types: [REQUEST_ACTION_TYPE, SUCCESS_ACTION_TYPE, ERROR_ACTION_TYPE],
 *             propagateErrors: true|false (default true)
 *         },
 *         other: 'value'
 *     }
 * ```
 * Will fire REQUEST_ACTION_TYPE, then SUCCESS_ACTION_TYPE with data in the key `data`
 * along with any other values in the initial API action (`other: 'value'`). The
 * ERROR_ACTION_TYPE is optional.
 *
 * @return {Promise} Resolves when data is retrieved.
 */
export default function () {
    return () => (next) => async (action) => {
        if (!action.api) return next(action);

        const { api, ...rest } = action;
        const { types, endpoint, method, body, token } = api;
        const [requestType, successType, errorType] = types;

        next({ ...rest, type: requestType });

        const onSuccess = (data) => {
            next({ ...rest, data, type: successType });
            return data;
        };

        const onError = (error) => {
            if (errorType) {
                next({ ...rest, error, type: errorType });
            }
        };

        if (endpoint) {
            try {
                let response = null;

                if (method && (method.toUpperCase() === 'POST' || method.toUpperCase() === 'PUT'|| method.toUpperCase() === 'DELETE')) {
                    // POST without/with token.
                    const options =  {
                        method,
                        body: JSON.stringify(body),
                        mode: 'cors',
                        headers: { 'Content-Type': 'application/json' }
                    }

                    if (token) {
                        options.headers['Authorization'] = `Bearer ${token}`;
                    }

                    response = await fetch(endpoint, options);

                } else if (token) {
                    // GET with token.
                    const options =  {
                        headers: { 'Authorization': `Bearer ${token}` }
                    }

                    response = await fetch(endpoint, options);

                } else {
                    // Plain old GET.
                    response = await fetch(endpoint);
                }

                if (!response.ok) {
                    const error = new Error(response.statusText || 'API Error');
                    error.status = response.status || 500;
                    throw error;
                }

                const data = response.headers.get('Content-Type').indexOf('application/json') === 0 ? await response.json() : await response.text();

                return onSuccess(data);
            } catch (err) {
                return onError(err);
            }
        }
    };
}
