import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as newsActions from '../actions/news-actions';
import News from '../components/news';

function mapStateToProps(state, props) {
  return {
    ...state.news
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(newsActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(News);
