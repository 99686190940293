import * as types from './action-types';

export function requestTopic(host, topic) {
    return {
        api: {
            endpoint: `${host}/news/${topic}`,
            types: [types.REQUEST_TOPIC, types.RECEIVE_TOPIC, types.ERROR]
        },
        topic
    };
}

export function requestArticle(host, url, length = '1000') {
    return {
        api: {
            endpoint: `${host}/scraper?url=${encodeURIComponent(url)}&length=${length}`,
            types: [types.REQUEST_ARTICLE, types.RECEIVE_ARTICLE, types.ERROR]
        },
        url,
        length
    };
}

export function removeImage() {
    return {
        type: types.REMOVE_IMAGE
    };
}
