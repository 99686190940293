import React, { Component } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';
import moment from 'moment';

import Grid from 'react-bootstrap/lib/Grid';
import Row from 'react-bootstrap/lib/Row';

import { Async } from 'react-select';
import 'react-select/dist/react-select.css';

import 'font-awesome/css/font-awesome.css';

import NewsNav from './news-nav.js';
import Error from './error';

class News extends Component {
    async getTopic(topic) {
        const { actions, host } = this.props;
        await actions.requestTopic(host, topic);
    }

    async getArticle(url, length) {
        const { actions, hostApi } = this.props;
        await actions.requestArticle(hostApi, url, length);
    }

    parseQueryString(search) {
        const pairs = search.slice(1).split('&');
        const result = {};
        pairs.forEach(function(pair) {
            pair = pair.split('=');
            result[pair[0]] = decodeURIComponent(pair[1] || '');
        });
        return result;
    }

    async getTopicOrArticle(topic, search) {
        if (topic === 'article') {
            const { url, length } = this.parseQueryString(search);
            await this.getArticle(url, length);
        } else {
            await this.getTopic(topic);
        }
    }

    getUniqueLocation(location) {
        const { pathname, search } = location;
        const essentialLocation = {
            pathname,
            search
        }
        return JSON.stringify(essentialLocation);
    }

    async removeImage() {
        const { actions } = this.props;
        await actions.removeImage();
    }

    // Will happen on server and first time on client.
    async componentWillMount() {
        const { location } = this.props;
        const topic = location && location.pathname && location.pathname.length > 1 ? location.pathname.substr(1) : 'news';
        await this.getTopicOrArticle(topic, location.search);
    }

    // Will happen on client for each "route".
    async componentWillReceiveProps(nextProps) {
        if (this.getUniqueLocation(nextProps.location) === this.getUniqueLocation(this.props.location)) return;
        const { location } = nextProps;
        const topic = location && location.pathname && location.pathname.length > 1 ? location.pathname.substr(1) : 'news';
        await this.getTopicOrArticle(topic, location.search);
    }

    render() {
        const { topics, isLoading, currentTopic, article, currentArticleUrl, error, hostApi, isFullArticle, isFullArticleLoading } = this.props;
        const firstTopic = topics && topics.length ? topics[0] : null;
        const title = isLoading ? "Loading..." : (firstTopic && firstTopic.title ? firstTopic.title : "Top Stories");
        const now = moment();

        const loadSearchOptions = (input) => {
            return fetch(`${hostApi}/complete?q=${input}`)
                .then((response) => {
                    return response.json();
                }).then((json) => {
                    return { options: json };
                });
        };

        const onSearchChange = (event) => {
            this.props.history.push(`/${event.value}`);
        };

        let byline = '';
        if (article) {
            const articleDate = article.date ? moment(article.date) : now;
            byline = (articleDate.isAfter(now) ? now : articleDate).fromNow();
            const a = document.createElement('a');
            a.href = article.canonicalLink;
            byline = byline ? `${byline} - ${a.hostname}` : a.hostname;
        }

        let ui = [];
        ui.push(
            <Row key={ui.length} data-article={article ? 'true' : 'false'}>
                <Helmet title={`${title} - Lucas News`} />
                {isLoading &&
                    <div className="news-component-topic">
                        <span className="news-component-title">{title}&nbsp;<i className="fa fa-refresh fa-spin news-component-loading"></i></span>
                    </div>
                }
                {!isLoading && !error && !article &&
                    <Async
                        name="search"
                        loadOptions={loadSearchOptions}
                        onChange={onSearchChange}
                        className="search"
                        placeholder="Search..."
                    />
                }
                {!error && !article && topics && topics.map((topic, i) =>
                    <div key={i} className="news-component-topic">
                        <span className="news-component-title"><Link to={`/${topic.topic}`}>{topic.title}</Link></span>
                        <ul>
                            {(topic && topic.items ? topic.items : []).map((item, j) => {
                                const formattedPubDate = (moment(item.pubDate).isAfter(now) ? now : moment(item.pubDate)).fromNow();
                                return (
                                    <li key={j} className="news-item">
                                        {topic.topic === 'live-scores' &&
                                            <a href={item.link}>{item.title}</a>
                                        }
                                        {topic.topic !== 'live-scores' &&
                                            <Link to={`/article?url=${encodeURIComponent(item.link)}`}>{item.title}</Link>
                                        }
                                        <div>
                                            <span className="news-source">{item.source}</span> - <span>{formattedPubDate}</span>
                                            {item.comments &&
                                                <span className="news-comments"> - <a href={item.comments}>comments</a></span>
                                            }
                                        </div>
                                    </li>
                                )
                            })}
                        </ul>
                        {currentTopic !== 'news' && i === (topics.length - 1) &&
                            <article>
                                <Link to="/">&lt; Back</Link>
                            </article>
                        }
                    </div>
                )}
                {!error && article &&
                    <article>
                        <h3>{article.softTitle}</h3>
                        <p className="subtle">
                            {byline}
                        </p>
                        {article.image &&
                            <img src={article.image} alt={article.title} onError={() => this.removeImage()} />
                        }
                        <p className="text" dangerouslySetInnerHTML={{ __html: article.text }} />
                        <Link to={`/${currentTopic || ''}`}>&lt; Back</Link>
                        { !isFullArticle && article.text.endsWith('...') &&
                            <Link className="moreLink" to={`/article?url=${encodeURIComponent((article && article.canonicalLink) || currentArticleUrl)}&length=full`} data-url={currentArticleUrl} onClick={(e) => {this.getArticle(e.target.dataset.url, 'full');e.preventDefault();}}>{isFullArticleLoading ? '' : 'More...'}</Link>
                        }
                        { isFullArticleLoading &&
                            <i style={{ padding: '0 15px' }} className="fa fa-refresh fa-spin news-component-loading"></i>
                        }
                        <span className="articleLinks">
                            <a href={(article && article.canonicalLink) || currentArticleUrl}>Full &gt;</a>&nbsp;&nbsp;&nbsp;
                            {article.ampUrl &&
                                <a href={article.ampUrl}>AMP &gt;</a>
                            }
                        </span>
                    </article>
                }
                {error &&
                    <article>
                        <Error {...error} />
                        <Link to={`/${currentTopic || ''}`}>&lt; Back</Link>
                        {currentArticleUrl &&
                            <span className="articleLinks">
                                <a href={(article && article.canonicalLink) || currentArticleUrl}>Full &gt;</a>
                            </span>
                        }
                    </article>
                }
            </Row>
        );

        return (
            <Grid>
                <NewsNav {...this.props} />
                <Grid className="news">
                    {ui}
                </Grid>
            </Grid>
        );
    }
}

News.propTypes = {
    actions: PropTypes.object.isRequired,
    error: PropTypes.object,
    host: PropTypes.string,
    hostApi: PropTypes.string,
    topics: PropTypes.array,
    isLoading: PropTypes.bool,
    currentTopic: PropTypes.string,
    currentArticleUrl: PropTypes.string,
    article: PropTypes.object,
    isFullArticle: PropTypes.bool,
    isFullArticleLoading: PropTypes.bool
};

export default withRouter(News);
